define("discourse/plugins/discourse-calendar/discourse/initializers/disable-sort", ["exports", "discourse/lib/plugin-api", "discourse-common/utils/decorators"], function (_exports, _pluginApi, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = {
    name: "disable-sort",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8", api => {
        var _dec, _obj;
        api.modifyClass("component:topic-list", (_dec = (0, _decorators.default)("category", "siteSettings.disable_resorting_on_categories_enabled"), (_obj = {
          pluginId: "discourse-calendar",
          sortable(category, disable_resorting_on_categories_enabled) {
            let disableSort = false;
            if (disable_resorting_on_categories_enabled && category?.custom_fields) {
              disableSort = !!category.custom_fields["disable_topic_resorting"];
            }
            return !!this.changeSort && !disableSort;
          }
        }, (_applyDecoratedDescriptor(_obj, "sortable", [_dec], Object.getOwnPropertyDescriptor(_obj, "sortable"), _obj)), _obj)));
      });
    }
  };
});