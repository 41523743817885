define("discourse/plugins/discourse-calendar/discourse/controllers/admin-plugins-calendar", ["exports", "@ember/controller", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _controller, _object, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = _controller.default.extend((_obj = {
    selectedRegion: null,
    loading: false,
    async getHolidays(region_code) {
      if (this.loading) {
        return;
      }
      this.set("selectedRegion", region_code);
      this.set("loading", true);
      return (0, _ajax.ajax)(`/admin/discourse-calendar/holiday-regions/${region_code}/holidays`).then(response => {
        this.model.set("holidays", response.holidays);
      }).catch(_ajaxError.popupAjaxError).finally(() => this.set("loading", false));
    }
  }, (_applyDecoratedDescriptor(_obj, "getHolidays", [_object.action], Object.getOwnPropertyDescriptor(_obj, "getHolidays"), _obj)), _obj));
});