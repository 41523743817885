define("discourse/plugins/discourse-calendar/discourse/components/event-date", ["exports", "@glimmer/component", "@ember/service", "discourse-i18n", "discourse/plugins/discourse-calendar/discourse/lib/guess-best-date-format", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _discourseI18n, _guessBestDateFormat, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _EventDate;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let EventDate = _exports.default = (_class = (_EventDate = class EventDate extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "siteSettings", _descriptor, this);
    }
    get shouldRender() {
      return this.siteSettings.discourse_post_event_enabled && this.args.topic.event_starts_at;
    }
    get eventStartedAt() {
      return this._parsedDate(this.args.topic.event_starts_at);
    }
    get eventEndedAt() {
      return this.args.topic.event_ends_at ? this._parsedDate(this.args.topic.event_ends_at) : this.eventStartedAt;
    }
    get dateRange() {
      return this.args.topic.event_ends_at ? `${this._formattedDate(this.eventStartedAt)} → ${this._formattedDate(this.eventEndedAt)}` : this._formattedDate(this.eventStartedAt);
    }
    get localDateContent() {
      return this._formattedDate(this.eventStartedAt);
    }
    get relativeDateType() {
      if (this.isWithinDateRange) {
        return "current";
      }
      if (this.eventStartedAt.isAfter(moment())) {
        return "future";
      }
      return "past";
    }
    get isWithinDateRange() {
      return this.eventStartedAt.isBefore(moment()) && this.eventEndedAt.isAfter(moment());
    }
    get relativeDateContent() {
      // dateType "current" uses a different implementation
      const relativeDates1 = {
        future: this.eventStartedAt.from(moment()),
        past: this.eventEndedAt.from(moment())
      };
      return relativeDates1[this.relativeDateType];
    }
    get timeRemainingContent() {
      return _discourseI18n.default.t("discourse_calendar.discourse_post_event.topic_title.ends_in_duration", {
        duration: this.eventEndedAt.from(moment())
      });
    }
    _parsedDate(date1) {
      return moment.utc(date1).tz(moment.tz.guess());
    }
    _guessedDateFormat() {
      return (0, _guessBestDateFormat.default)(this.eventStartedAt);
    }
    _formattedDate(date1) {
      return date1.format(this._guessedDateFormat());
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{~#if this.shouldRender~}}
        <span class="header-topic-title-suffix-outlet event-date-container">
          {{~#if this.siteSettings.use_local_event_date~}}
            <span
              class="event-date event-local-date past"
              title={{this.dateRange}}
              data-starts-at={{this.eventStartedAt}}
              data-ends-at={{this.eventEndedAt}}
            >
              {{this.localDateContent}}
            </span>
          {{else}}
            <span
              class="event-date event-relative-date {{this.relativeDateType}}"
              title={{this.dateRange}}
              data-starts-at={{this.eventStartedAt}}
              data-ends-at={{this.eventEndedAt}}
            >
              {{~#if this.isWithinDateRange~}}
                <span class="indicator"></span>
                <span class="text">{{this.timeRemainingContent}}</span>
              {{else}}
                {{this.relativeDateContent}}
              {{~/if~}}
            </span>
          {{~/if~}}
        </span>
      {{~/if~}}
    
  */
  {
    "id": "RjIMaT3A",
    "block": "[[[41,[30,0,[\"shouldRender\"]],[[[10,1],[14,0,\"header-topic-title-suffix-outlet event-date-container\"],[12],[41,[30,0,[\"siteSettings\",\"use_local_event_date\"]],[[[10,1],[14,0,\"event-date event-local-date past\"],[15,\"title\",[30,0,[\"dateRange\"]]],[15,\"data-starts-at\",[30,0,[\"eventStartedAt\"]]],[15,\"data-ends-at\",[30,0,[\"eventEndedAt\"]]],[12],[1,\"\\n            \"],[1,[30,0,[\"localDateContent\"]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],[[[1,\"          \"],[10,1],[15,0,[29,[\"event-date event-relative-date \",[30,0,[\"relativeDateType\"]]]]],[15,\"title\",[30,0,[\"dateRange\"]]],[15,\"data-starts-at\",[30,0,[\"eventStartedAt\"]]],[15,\"data-ends-at\",[30,0,[\"eventEndedAt\"]]],[12],[41,[30,0,[\"isWithinDateRange\"]],[[[10,1],[14,0,\"indicator\"],[12],[13],[1,\"\\n              \"],[10,1],[14,0,\"text\"],[12],[1,[30,0,[\"timeRemainingContent\"]]],[13],[1,\"\\n\"]],[]],[[[1,\"              \"],[1,[30,0,[\"relativeDateContent\"]]]],[]]],[13]],[]]],[13]],[]],null]],[],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-calendar/discourse/components/event-date.js",
    "isStrictMode": true
  }), _EventDate), _EventDate), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});