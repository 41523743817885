define("discourse/plugins/discourse-calendar/discourse/initializers/add-upcoming-events-to-sidebar", ["exports", "discourse/lib/plugin-api", "discourse-i18n"], function (_exports, _pluginApi, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  var _default = _exports.default = {
    name: "add-upcoming-events-to-sidebar",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      if (siteSettings.discourse_post_event_enabled && siteSettings.sidebar_show_upcoming_events) {
        (0, _pluginApi.withPluginApi)("0.8.7", api => {
          api.addCommunitySectionLink(baseSectionLink => {
            return class UpcomingEventsSectionLink extends baseSectionLink {
              constructor() {
                super(...arguments);
                _defineProperty(this, "name", "upcoming-events");
                _defineProperty(this, "route", "discourse-post-event-upcoming-events");
                _defineProperty(this, "text", _discourseI18n.default.t("discourse_calendar.discourse_post_event.upcoming_events.title"));
                _defineProperty(this, "title", _discourseI18n.default.t("discourse_calendar.discourse_post_event.upcoming_events.title"));
              }
            };
          });
        });
      }
    }
  };
});