define("discourse/plugins/discourse-calendar/discourse/components/modal/post-event-invite-user-or-group", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _ajax, _ajaxError, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    @title={{i18n
      "discourse_calendar.discourse_post_event.invite_user_or_group.title"
    }}
    @closeModal={{@closeModal}}
    @flash={{this.flash}}
  >
    <:body>
      <form>
        <EventField>
          <EmailGroupUserChooser
            @value={{this.invitedNames}}
            @options={{hash
              fullWidthWrap=true
              includeMessageableGroups=true
              filterPlaceholder="composer.users_placeholder"
              excludeCurrentUser=true
            }}
          />
        </EventField>
      </form>
    </:body>
    <:footer>
      <DButton
        @type="button"
        class="btn-primary"
        @label="discourse_calendar.discourse_post_event.invite_user_or_group.invite"
        @action={{this.invite}}
      />
    </:footer>
  </DModal>
  */
  {
    "id": "IcCdkHp2",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@closeModal\",\"@flash\"],[[28,[37,1],[\"discourse_calendar.discourse_post_event.invite_user_or_group.title\"],null],[30,1],[30,0,[\"flash\"]]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[10,\"form\"],[12],[1,\"\\n      \"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],null,[[\"@value\",\"@options\"],[[30,0,[\"invitedNames\"]],[28,[37,4],null,[[\"fullWidthWrap\",\"includeMessageableGroups\",\"filterPlaceholder\",\"excludeCurrentUser\"],[true,true,\"composer.users_placeholder\",true]]]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,5],[[24,0,\"btn-primary\"]],[[\"@type\",\"@label\",\"@action\"],[\"button\",\"discourse_calendar.discourse_post_event.invite_user_or_group.invite\",[30,0,[\"invite\"]]]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\"i18n\",\"event-field\",\"email-group-user-chooser\",\"hash\",\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-calendar/discourse/components/modal/post-event-invite-user-or-group.hbs",
    "isStrictMode": false
  });
  let PostEventInviteUserOrGroup = _exports.default = (_class = class PostEventInviteUserOrGroup extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "invitedNames", _descriptor, this);
      _initializerDefineProperty(this, "flash", _descriptor2, this);
    }
    async invite() {
      try {
        await (0, _ajax.ajax)(`/discourse-post-event/events/${this.args.model.event.id}/invite.json`, {
          data: {
            invites: this.invitedNames
          },
          type: "POST"
        });
        this.args.closeModal();
      } catch (e) {
        this.flash = (0, _ajaxError.extractError)(e);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "invitedNames", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "flash", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "invite", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "invite"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PostEventInviteUserOrGroup);
});