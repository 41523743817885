define("discourse/plugins/discourse-calendar/discourse/components/bulk-invite-sample-csv-file", ["exports", "@ember/component", "@ember/object", "@ember/template-factory"], function (_exports, _component, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DButton
    @label="discourse_calendar.discourse_post_event.bulk_invite_modal.download_sample_csv"
    @action={{action "downloadSampleCsv"}}
  />
  */
  {
    "id": "/3bi2HOZ",
    "block": "[[[8,[39,0],null,[[\"@label\",\"@action\"],[\"discourse_calendar.discourse_post_event.bulk_invite_modal.download_sample_csv\",[28,[37,1],[[30,0],\"downloadSampleCsv\"],null]]],null]],[],false,[\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-calendar/discourse/components/bulk-invite-sample-csv-file.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend((_obj = {
    downloadSampleCsv() {
      const sampleData = [["my_awesome_group", "going"], ["lucy", "interested"], ["mark", "not_going"], ["sam", "unknown"]];
      let csv = "";
      sampleData.forEach(row => {
        csv += row.join(",");
        csv += "\n";
      });
      const btn = document.createElement("a");
      btn.href = `data:text/csv;charset=utf-8,${encodeURI(csv)}`;
      btn.target = "_blank";
      btn.rel = "noopener noreferrer";
      btn.download = "bulk-invite-sample.csv";
      btn.click();
    }
  }, (_applyDecoratedDescriptor(_obj, "downloadSampleCsv", [_object.action], Object.getOwnPropertyDescriptor(_obj, "downloadSampleCsv"), _obj)), _obj)));
});